<template>
    <v-dialog
        v-if="visible"
        v-model="visible"
        transition="dialog-top-transition"
        scrollable
        persistent
        max-width="600"
    >
        <v-card class="modal-maincard">

            <v-toolbar dark flat dense>
                <v-toolbar-title>{{ $t(title) }}</v-toolbar-title>
            </v-toolbar>

            <v-card-text class="wrapperFormModal">
                <v-card 
                    flat
                >
                    <v-card-text>
                        
                        <v-form ref="form" lazy-validation>

                            <v-row no-gutters>
                                <v-col cols="12" sm="12" md="3">
                                    <label class="f-label">{{ $t("Срок_продления") }}</label>
                                </v-col>

                                <v-col cols="12" sm="12" md="9">
                                    <v-menu 
                                        v-model="prolongateDateMenu"
                                        :close-on-content-click="false"
                                        :nudge-right="40"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="290"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                                :value="prolongateDate | formattedDate"
                                                prepend-icon="mdi-calendar"
                                                readonly
                                                v-bind="attrs"
                                                v-on="on"
                                                outlined
                                                dense
                                                class="datepick-input"
                                                required
                                                :rules="requiredRule"
                                                hide-details
                                            >
                                            </v-text-field>
                                        </template>

                                        <v-date-picker
                                            v-model="prolongateDate"
                                            @input="prolongateDateMenu = false"
                                            color="teal"
                                            :min="min"
                                            :max="max"
                                            :first-day-of-week="1"
                                        >
                                        </v-date-picker>
                                    </v-menu>

                                    <div class="field-hint grey--text">
                                        <i>{{ $t('Рекомендуемый_срок') + ': ' + supposedDate }}</i>
                                    </div>

                                </v-col>

                            </v-row>

                            <v-row no-gutters>
                                <v-col cols="12" sm="12" md="3">
                                    <label class="f-label">{{ $t("Причина") }}</label>
                                </v-col>

                                <v-col cols="12" sm="12" md="9">
                                    <v-textarea
                                        v-model="prolongateReason"
                                        rows="3"
                                        no-resize                                        
                                        outlined
                                        dense
                                        required
                                        :rules="requiredRule"
                                        hide-details
                                    >
                                    </v-textarea>
                                </v-col>

                            </v-row>

                        </v-form>

                    </v-card-text>
                </v-card>

            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn 
                    color="cyan"
                    text
                    depressed
                    @click="onOk"
                    v-if="valid"
                >
                    {{ $t("Изменить") }} 
                </v-btn>

                <v-btn
                    color="blue-grey"
                    text
                    depressed
                    @click="onCancel"
                >
                    {{ $t("Отмена") }}
                </v-btn>

            </v-card-actions>

        </v-card>
    </v-dialog>
</template>

<script>
import sys from '@/services/system';
import { mapActions, mapGetters } from 'vuex';

export default {
    name: "ChangeControlDlg",
    filters: {
        formattedDate: function (value) {
            var formatedDate = sys.dateFormat(value, 'DD.MM.YYYY');
            return formatedDate ?? "";
        }
    },
    data () {
        return {
            title: "Изменить_срок",
            options: {
                color: 'grey lighten-3',
                width: 400,
                zIndex: 200,
                noconfirm: false,
            },
            prolongateDateMenu: false
        }
    },
    computed: {
        ...mapGetters({ 
            requiredRule: 'getRequiredRule',
        }),
        ...mapGetters(
            'global/dialogs/changeControl',
            { 
                visible: 'isVisible',
                valid: 'isValid',
                min: 'getMinDate',
                max: 'getMaxDate',
                supposedDate: 'getSupposedDate'
            }),
        prolongateDate: {
            get: function() {
                return this.$store.getters['global/dialogs/changeControl/getDate'];
            },
            set: function(v) {
                this.$store.commit('global/dialogs/changeControl/SET_DATE', v); 
            }
        },
        prolongateReason: {
            get: function() {
                return this.$store.getters['global/dialogs/changeControl/getReason'];
            },
            set: function(v) {
                this.$store.commit('global/dialogs/changeControl/SET_REASON', v); 
            }
        },
    },
    methods: {
        ...mapActions('global/dialogs/changeControl', ['ok', 'cancel']),
        async onOk() {
            this.prolongateDateMenu = false;
            await this.ok();
        },
        async onCancel() {
            this.prolongateDateMenu = false;
            await this.cancel();
        },
        validateFormMethod(){
            this.$refs?.form?.validate();    
        }
    },
    updated: function () {
        this.validateFormMethod();
    }
}
</script>